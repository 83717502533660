import { createContext } from 'react';
import { object } from 'yup';

export interface IWebSocketContext {
  webSocket: WebSocket | undefined;
  isWebSocketReady: boolean;
  webSocketFailed: boolean;
  sendMessage: (message: object) => void;
  getEnvStatus: (envSlug: string) => object;
}

export const WebSocketContext = createContext<IWebSocketContext>({
  webSocket: undefined,
  isWebSocketReady: false,
  webSocketFailed: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sendMessage: (message: object) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getEnvStatus: (envSlug: string) => object,
});
