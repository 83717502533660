import { ChakraProvider } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import React, { useState, useEffect, useRef } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { BrowserRouter as Router } from 'react-router-dom';

import { API_URL, WS_URL } from '../config';
import { useGetUserAccounts } from '../context/AccountContext/api/getUserAccounts';
import { Account } from '../context/AccountContext/types';
import { useGetUserInfo } from '../context/UserContext/api/getUserInfo';
import { User } from '../context/UserContext/types';
import { WebSocketContext } from '../features/global/websocket/WebSocketContext';
import { usePersistedState } from '../hooks/usePersistedState';
import { main } from '../themes';
import { getSiteContext } from '../utils/siteContext';

import { AccountContext } from './AccountContext';
import { TabsContext } from './TabsContext';
import { HasTabAccess, UserContext } from './UserContext';

type AppProviderProps = {
  children: React.ReactNode;
};

const isPublicPage = () => {
  // Public pages should not retrieve data from api
  return window.location.pathname === '/sign-in';
};

export const UIProvider = ({ children }: AppProviderProps) => {
  const [currentUser, setCurrentUser] = useState<User>();
  const [currentTab, setCurrentTab] = usePersistedState('currentTab', 'docs');
  const handleError = useErrorHandler();
  const [currentAccount, setAccount] = useState<Account | undefined>();
  const [accountSlug, setAccountSlug] = usePersistedState('accountSlug', undefined);
  const [accounts, setAccounts] = useState<Account[]>();
  const [webSocket, setWebSocket] = useState<WebSocket>();
  const [webSocketFailed, setWebSocketFailed] = useState<boolean>(false);
  const [isWebSocketReady, setWebSocketReady] = useState<boolean>(false);
  const [envStatusMessages, setEnvStatusMessages] = useState<object[]>([]);
  const siteContext = getSiteContext();
  const socketAttemptsConnection = useRef(1);
  const webSocketUrl = useRef('');

  const redirectOnError = (err: any) => {
    if (err.response && [401, 403].includes(err.response.status)) {
      window.location.href = `${API_URL}/iam/login?next=${window.location}`;
    } else {
      handleError(err);
    }
  };

  const datacovesSocket = () => {
    if (webSocket && webSocket.readyState == 1) {
      webSocket.close();
    }
    const socket = new WebSocket(webSocketUrl.current);
    socket.onopen = () => {
      console.log('[open] Connection opened.');
      setWebSocket(socket);
      setWebSocketReady(true);
      setWebSocketFailed(false);
      socketAttemptsConnection.current = 1;
    };
    socket.onclose = (event) => {
      if (!event.wasClean && socketAttemptsConnection.current) {
        if (socketAttemptsConnection.current <= 3) {
          socketAttemptsConnection.current++;
          setTimeout(() => datacovesSocket(), 1000);
        } else {
          console.error('[error] Websocket maximum connection attempts.');
          setWebSocketFailed(true);
        }
      } else {
        console.log('[close] Connection closed successful.');
      }
      setWebSocketReady(false);
    };
    socket.onerror = (error) => {
      console.error('[error]', error);
    };
    socket.onmessage = (event) => {
      const receivedMessage = JSON.parse(event.data);
      if (receivedMessage['message_type'] === 'env.status') {
        setEnvStatusMessages((prevState) => {
          const message = receivedMessage['message'];
          const idx = envStatusMessages.findIndex(
            (item) => Object.assign(item).env === message.env
          );
          if (idx) {
            prevState.splice(idx, 1);
          }
          return [...prevState, message];
        });
      }
    };
  };

  useEffect(() => {
    if (currentAccount) {
      const wsUrl = `${WS_URL}/ws/account/${currentAccount.slug}/`;
      if (webSocketUrl.current != wsUrl) {
        webSocketUrl.current = wsUrl;
        datacovesSocket();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount]);

  const sendMessage = async (message: object) => {
    waitForSocketConnection(() => webSocket?.send(JSON.stringify(message)));
  };

  const waitForSocketConnection = async (callback: any) => {
    setTimeout(() => {
      if (webSocket && webSocket.readyState == 1) {
        if (callback != null) {
          callback();
        }
      } else {
        waitForSocketConnection(callback);
      }
    }, 100); // Miliseconds
  };

  const getEnvStatus = (envSlug: string) => {
    if (envStatusMessages) {
      const items = envStatusMessages.filter((item) => Object.assign(item).env === envSlug);
      if (items) {
        return items[0];
      }
    }

    return {};
  };

  useGetUserInfo(
    {
      account: currentAccount?.slug,
      environment: siteContext.env,
    },
    {
      onSuccess: (resp: User) => {
        setCurrentUser(resp);
        // Sentry tags
        Sentry.setTag('user.slug', resp.slug);
        Sentry.setTag('account.slug', currentAccount?.slug);
        // Google Analytics properties
        if (window.gtag) {
          window.gtag('config', 'G-X8V16WM99D', {
            user_id: resp.slug,
          });
          window.gtag('set', 'user_properties', {
            user_slug: resp.slug,
            account_slug: currentAccount?.slug,
          });
        }
      },
      onError: redirectOnError,
      enabled: !isPublicPage() && !!accounts,
    }
  );

  useGetUserAccounts({
    onSuccess: (accounts: Account[]) => {
      setAccounts(accounts);
      if (accounts.length > 0) {
        if (accountSlug) {
          const storedAccount = accounts.filter((acc) => acc.slug === accountSlug);
          if (storedAccount.length > 0) {
            setAccount(storedAccount[0]);
          } else {
            setAccount(accounts[0]);
          }
        } else {
          setAccount(accounts[0]);
        }
      }
    },
    onError: redirectOnError,
    enabled: !isPublicPage(),
  });

  useEffect(() => {
    if (currentUser && !HasTabAccess(currentUser, currentTab)) {
      setCurrentTab('docs');
    }
  }, [currentTab, currentUser, setCurrentTab]);

  function setCurrentAccount(account: Account | undefined) {
    setAccount(account);
    setAccountSlug(account?.slug);
  }

  return (
    <ChakraProvider resetCSS theme={main}>
      <AccountContext.Provider value={{ currentAccount, setCurrentAccount, accounts, setAccounts }}>
        <WebSocketContext.Provider
          value={{ webSocket, isWebSocketReady, webSocketFailed, sendMessage, getEnvStatus }}
        >
          <TabsContext.Provider value={{ currentTab, setCurrentTab }}>
            <UserContext.Provider value={{ currentUser, setCurrentUser }}>
              <Router>{children}</Router>
            </UserContext.Provider>
          </TabsContext.Provider>
        </WebSocketContext.Provider>
      </AccountContext.Provider>
    </ChakraProvider>
  );
};
